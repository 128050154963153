const formatDateWithOrdinal = (date, includeTime = true) => {

  date = new Date(date)
  const formatTime = () => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes}${ampm}`;
  }

  const getSuffix = () => {
    const day = date.getDate();
    return day % 10 === 1 && day !== 11 ? "st" :
      day % 10 === 2 && day !== 12 ? "nd" :
        day % 10 === 3 && day !== 13 ? "rd" : "th";
  }



  const month = date.toLocaleString("en-US", {
    month: "short",
  });



  const getYear = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    return date.getFullYear() === currentYear ? `` : `, ${date.getFullYear()}`;
  }

  if (!includeTime) {
    return `${month} ${date.getDate()}${getSuffix()}${getYear()}`
  }

  const year = getYear();

  if (year) {
    return `${month} ${date.getDate()}${getSuffix()}${year}, ${formatTime()}`
  }
  return `${month} ${date.getDate()}${getSuffix()} at ${formatTime()}`

};

export { formatDateWithOrdinal }