const minutesInFuture = 1440 + 1440 + 1440
const expiresOn = new Date(new Date().getTime() + (minutesInFuture * 60000)).toISOString()



const PercentOffSelectItems = {
  "anythingToDisplay": true,
  "title": "Final price: $140.39",
  "subTitle": "Includes 20% off select items",
  "appliedMessage": "Promotion PDP_TEST_PROMO_3 is automatically applied at checkout",
  "expiresOn": expiresOn,
  "priceAfterPromo": {
    "strikeThroughPrice": {
      "decimal": 194.99,
      "string": "$194.99"
    },
    "listPrice": {
      "decimal": 140.39,
      "string": "$140.39"
    },
    "listPriceSavings": {
      "dollars": {
        "decimal": 54.6,
        "string": "$54.60"
      },
      "percent": {
        "decimal": 0.2800143597107544,
        "string": "28%"
      }
    },
    "optimisticPrice": {
      "decimal": 140.39,
      "string": "$140.39"
    },
    "optimisticPriceSavings": {
      "dollars": {
        "decimal": 54.6,
        "string": "$54.60"
      },
      "percent": {
        "decimal": 0.2800143597107544,
        "string": "28%"
      }
    }
  }
}
export {
  PercentOffSelectItems,
}