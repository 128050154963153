import { useState, useEffect } from "react";

import Typography from "Clutch/Atoms/Typography/Typography";
import Button from "Clutch/Molecules/Button/Button";
import Svg from "Clutch/Atoms/Svg";
import Textbox from "Clutch/Atoms/Form/Elements/Textbox";
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { sendFitmentFeedbackToZendesk } from "./sendFitmentFeedbackToZendesk";

import styles from "./engineFitmentFeedback.module.scss";

const FitmentFeedbackConfirmation = ({
  fitmentRow,
  closeFunc,
  skuNumber,
  engineId,
  vehicleBaseId,
  raceTypeId,
  marketId,
  animation,
  requestFitment,
  setRequestFitmentSuccess,
  isUniversalEngineFitment
}) => {
  const hydrated = useUserStore((x) => x.context.hydrated);
  const emailAddress = useUserStore((x) => x.context.emailAddress);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const universalFitMessage = 
    "This product has been assigned Universal Fitment as it is not designed or intended to be used in any specific application. "
    + "If you believe this is in error and this product should have specific Engine Fitment, please submit your feedback and we will evaluate accordingly."

  useEffect(() => {
    if (hydrated && emailAddress)
      setUserEmail(emailAddress);
  }, [hydrated, emailAddress]);

  async function handleSubmit(userMessage, userEmailInput) {
    if (!userMessage) {
      setError("Enter a reason for complaint.");
      return;
    }
    if (!userEmail && !userEmailInput) {
      setError("Enter your email address.");
      return;
    }
    if (userEmail || userEmailInput) {
      setIsLoading(true);

      var input = {
        skuVariant: skuNumber,
        reporterEmail: userEmail ?? userEmailInput,
        reporterMessage: userMessage
      };

      if (!requestFitment) {
        if (engineId) input.engineDefinitionId = engineId;
        if (vehicleBaseId) input.vehicleBaseId = vehicleBaseId;
        if (raceTypeId) input.raceTypeId = raceTypeId;
        if (marketId) input.marketId = marketId;
      }
      var success = await sendFitmentFeedbackToZendesk(input);
      if (requestFitment) setRequestFitmentSuccess(success)
      setSuccess(success);
      setIsLoading(false);
    }
  }

  if ((!fitmentRow && !requestFitment) || !animation) return false;

  return (
    <div className={styles.confirmation} animation={animation.toString()} extraheight={(!userEmail || !!isUniversalEngineFitment).toString()}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          if (userEmail) {
            handleSubmit(e.target[0].value);
          } else {
            handleSubmit(e.target[1].value, e.target[0].value);
          }
        }}
      >
        <Typography font="bold" size={1.25}>
          {requestFitment ? 'Request Engine Fitment' : `Flag fitment for ${fitmentRow}?`}
        </Typography>
        {!userEmail && (
          <Textbox
            label={"Enter your email address"}
            className={styles.textbox}
            fill
          />
        )}
        {isUniversalEngineFitment &&
          <Typography
            intent={"warning"}
            font="bold"
            className={styles.universalFitMessage}
          >
            {universalFitMessage}
            </Typography>}
        <Textbox
          label={requestFitment ? "Reason for request" : "Reason for complaint"}
          className={styles.textbox}
          fill
        />
        <Button
          className={styles.complaintButton}
          intent={"action"}
          size={"small"}
          isWaiting={isLoading}
          disabled={success}
          segmentEvent={{ event: "engineFitmentFeedbackComplaintSubmission" }}
          type={"submit"}
          text={requestFitment ? "Yes, Request Fitment": "Yes, Submit Complaint"}
        ></Button>
        {success && (
          <Typography>{requestFitment ? 'Fitment requested' : 'Feedback submitted'} successfully. Thank you!</Typography>
        )}
        {!success && error && (
          <Typography intent="negative" className={styles.error}>{error}</Typography>
        )}
      </form>

      {!requestFitment && <div className={styles.close} onClick={closeFunc}>
        <Svg icon="close" size={1} />
      </div>}
    </div>
  );
};

export default FitmentFeedbackConfirmation;
