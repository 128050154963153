import React, { Fragment, useEffect, useState } from 'react'
import Svg from 'Clutch/Atoms/Svg'

import Typography from 'Clutch/Atoms/Typography'
import GetTimeAgoString from 'Clutch/Utilities/DateTime/TimeAgo';
import { PercentOffSelectItems } from './mockPromos'
import { useProductPageStore } from '../../../contexts/ProductPageStore'
import RestRequest from "Utilities/RestClient/RestClient";
import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag'
import styles from './promodisplay.module.scss'
import { formatDateWithOrdinal } from './dateutils';

const PromoDisplay = () => {
  const [promo, setPromo] = useState(null);
  const updatePricesForPromo = useProductPageStore(x => x.updatePricesForPromo)
  const isLazyDataHydrated = useProductPageStore(x => x.isLazyDataHydrated)
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);

  const featureFlag = useFeatureFlag("PromotionPreview_PDP_Frontend");

  const fetchPromo = async () => {
    if (!isLazyDataHydrated) {
      return
    }

    if (!featureFlag) {
      return;
    }
    var promoResponse = await RestRequest(`/api/checkout/promotionpreview/singleitem`, null, { skuVariantNumber, skuBaseNumber });
    if (promoResponse?.anythingToDisplay) {
      console.log("Promotion shown")
      if (promoResponse.priceAfterPromo.listPriceSavings.dollars.decimal !== 0) {
        updatePricesForPromo({
          strikeThroughPrice: promoResponse.priceAfterPromo.strikeThroughPrice.string,
          price: promoResponse.priceAfterPromo.listPrice.string,
          retailLowPrice: promoResponse.priceAfterPromo.listPrice.string,
          savingsPrice: promoResponse.priceAfterPromo.listPriceSavings.dollars.string
        })
      }

    }
    setPromo(promoResponse);
  }


  useEffect(() => {
    fetchPromo();
  }, [skuVariantNumber, isLazyDataHydrated, featureFlag])

  if (!promo || !promo.anythingToDisplay) {
    return null;
  }


  return <div className={styles.container} >
    <Expiration expiresOn={promo.expiresOn} />
    <Typography font={'bold'} size={1.25}>{promo.title}</Typography>
    <Typography size={1}>{promo.extendedTitle}</Typography>
    <Typography size={0.875}>{promo.subTitle}</Typography>
    <Typography size={0.75} tone={'subtle'}>{promo.appliedMessage}</Typography>

  </div>

}



const Expiration = ({ expiresOn }) => {
  if (!expiresOn) {
    return null;
  }

  const expiresOnDate = new Date(expiresOn);

  const oneDay = 24 * 60 * 60 * 1000;

  const diffDays = Math.abs((expiresOnDate - new Date()) / oneDay);

  if (diffDays < 0) {
    return null;
  }

  if (diffDays > 180) {
    return null;
  }

  if (diffDays > 14) {
    return <ExpiresInRow size={0.875} >
      <>on {formatDateWithOrdinal(expiresOnDate, false)}</>
    </ExpiresInRow>

  }

  if (diffDays > 2) {
    return <ExpiresInRow size={1} intent={`warning`} >
      <>{GetTimeAgoString(expiresOn)} ({formatDateWithOrdinal(expiresOnDate)})</>
    </ExpiresInRow>
  }

  return <CountDown expiresOnDate={expiresOnDate} />

}
const CountDown = ({ expiresOnDate }) => {
  const [displayMessage, setDisplayMessage] = useState(null)

  const formatNumber = (number) => {
    return number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
  }

  const setMessage = () => {
    let milli = expiresOnDate - new Date();
    const seconds = Math.round(milli / 1000);

    const hours = Math.floor(seconds / 3600)
    let remainingSeconds = seconds % 3600
    const minutes = Math.floor(remainingSeconds / 60)
    remainingSeconds = remainingSeconds % 60

    setDisplayMessage(`${hours}:${formatNumber(minutes)}:${formatNumber(remainingSeconds)}`)

  }

  useEffect(() => {
    setMessage()
    setInterval(
      setMessage,
      1000
    )
  }, [])

  return <ExpiresInRow intent={`warning`} >
    in {displayMessage}
  </ExpiresInRow>

}


const ExpiresInRow = ({ children, size = 1, intent = null }) => {
  return <Typography font={"bold"} className={styles.expirationContain} intent={intent} size={size}><Svg size={size} intent={intent} icon={`timer`}></Svg> Offer expires {children}</Typography>

}







export default PromoDisplay