import { useEffect, useState, useMemo } from 'react'
import getCustomerImages from './fetches/customerImages'
import Tabs from 'Clutch/Components/Tabs/Tabs'
import { combineImagesAndVideos } from './galleryUtils'
import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag'
import ImpressionBoundary from "Clutch/UtilityComponents/ImpressionBoundary";

import styles from './gallery.module.scss'

const GalleryTabs = ({ skuBase, setMedia, onChange }) => {
  try {

    let customerImagesFlag = useFeatureFlag("PDP_Customer_Images");

    const [hasCustomerImages, setHasCustomerImages] = useState(false)

    useEffect(() => {
      if (customerImagesFlag) {
        getCustomerImages(skuBase).then((images) => {
          if (images?.imageUrls?.length) {
            setMedia(media => {
              return {
                ...media,
                customer: combineImagesAndVideos(images?.imageUrls, null, null, false, true)
              }
            })
            setHasCustomerImages(true)
          }
        })
      }
    }, [customerImagesFlag])

    return (
      <>
        <div className={styles.imageTabs}>
          {hasCustomerImages ? 
          <ImpressionBoundary sectionName={'Gallery_Image_Tabs'}>
            <Tabs brand={'primary'} size={0.875} onChange={onChange} dataTestId={"imageTabs"} items={[
              {
                label: "Product Images",
                key: "product",
              },
              {
                label: "Customer Images",
                key: "customer"
              }
            ]} />
          </ImpressionBoundary>
          : false}
        </div>
      </>
    )
  }
  catch (err) {
    console.error(err)
    return false
  }
}

export default GalleryTabs;