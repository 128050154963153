import React, { useContext } from "react";
import Area from "Clutch/Molecules/Area";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import ImpressionBoundary from "Clutch/UtilityComponents/ImpressionBoundary"
import styles from "./mightFit.module.scss";
import { useProductPageStore } from "../../../../../contexts/ProductPageStore";
import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";
import drawerStates from "Clutch/Organisms/CustomerProjectDrawer/Constants/drawerStates"

const MightFit = ({ selectedVehicle, promptUserFor }) => {
  const showAdditionalFacetDrawer = useProductPageStore((x) => x.showAdditionalFacetDrawer);
  const { dispatch: navigationDispatch } = useContext(NavigationContext);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber)
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber)
  const fitment = useProductPageStore(x => x.fitment);

  if(!selectedVehicle) return

  let vehicleDisplayText;
  let DescriptionComponent;
  let ctaText;
  let ctaTextLinkOnClick;

  if (selectedVehicle.vehicleBaseId)
    vehicleDisplayText = `${selectedVehicle.year} ${selectedVehicle.model}`;
  else if (selectedVehicle.raceTypeId)
    vehicleDisplayText = selectedVehicle.raceName;
  else if (selectedVehicle.engineDisplayName)
    vehicleDisplayText = selectedVehicle.engineDisplayName;

  if (promptUserFor === "RaceType") {
    DescriptionComponent = (
      <Typography size={0.875}>
        {"Verify the Race Type for your "}
        <Typography font={"bold"} inline>
          {vehicleDisplayText}
        </Typography>
      </Typography>
    );
    ctaText = "Add Race Type";
    ctaTextLinkOnClick = () => navigationDispatch({
      type: "setCustomerProjectDrawerState",
      payload: {
        garageState: {
          isCustomerProjectDrawerOpen: true,
          customerProjectDrawerView: drawerStates.updateProjectAddRaceType,
        },
      },
    })
  } else if (promptUserFor === "Vehicle") {
    DescriptionComponent = (
      <Typography size={0.875}>
        {"Verify the Vehicle for your "}
        <Typography font={"bold"} inline>
          {vehicleDisplayText}
        </Typography>
      </Typography>
    );
    ctaText = "Add Vehicle";
    ctaTextLinkOnClick = () => navigationDispatch({
      type: "setCustomerProjectDrawerState",
      payload: {
        garageState: {
          isCustomerProjectDrawerOpen: true,
          customerProjectDrawerView: drawerStates.updateProjectAddYMM
        },
      },
    })
  } else if (promptUserFor === "Engine") {
    DescriptionComponent = (
      <Typography size={0.875}>
        {"Verify the Engine for your "}
        <Typography font={"bold"} inline>
          {vehicleDisplayText}
        </Typography>
      </Typography>
    );
    ctaText = "Add Engine";
    ctaTextLinkOnClick = () => navigationDispatch({
      type: "setCustomerProjectDrawerState",
      payload: {
        garageState: {
          isCustomerProjectDrawerOpen: true,
          customerProjectDrawerView: drawerStates.updateProjectAddEngine,
        },
      },
    })
  } else if (promptUserFor === "AdditionalFacets") {
    DescriptionComponent = (
      <Typography size={0.875}>
        {
          "This part may fit your vehicle and engine, complete options to verify fitment."
        }
      </Typography>
    );
    ctaText = "Complete Vehicle";
    ctaTextLinkOnClick = () => showAdditionalFacetDrawer()
  }

  const additionalProps = {
    vehicleDisplayText: vehicleDisplayText,
    ctaText: ctaText,
    promptUserFor: promptUserFor,
    skuNumber: skuVariantNumber ?? skuBaseNumber,
    fitment: {...fitment?.[0]?.value},
    additionalEngineFacetType: fitment?.[0]?.value?.additionalEngineLevelFacets?.displayName
  }

  return (
    <ErrorBoundary>
      <ImpressionBoundary sectionName={"PDP Might Fit"} additionalProps={additionalProps}>
        <Area
          intent={"warning"}
          icon={"warning"}
          className={styles.vehicle_fitment}
        >
          <Typography font={"bold"}>This Part Might Fit</Typography>
          {DescriptionComponent}
          <TextLink
            className={styles.vehicle_fitment_link}
            size={0.875}
            chevron={"right"}
            onClick={ctaTextLinkOnClick}
          >
            {ctaText}
          </TextLink>
        </Area>
      </ImpressionBoundary>
    </ErrorBoundary>
  );
};

export default MightFit;
